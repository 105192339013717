<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs9 text-left pb-2>
        <span class="mainHeader">ROLES</span>
      </v-flex>
      <v-flex xs3 text-right>
        <v-btn dark color="#3F053C" @click="addRole = true" class="mr-2"> Add Role </v-btn>
    
      </v-flex>
      <v-flex xs12 py-15 text-left v-if="rolesList">
        <v-card
        class="rounded-lg"
        elevation="0"
          v-if="rolesList.length > 0"
          tile
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="rolesList.length>0">
            <v-flex xs12>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left tablefont"
                        style="font-size: 15px; color: black"
                      >
                        <b>S.No.</b>
                      </th>
                      <th
                        class="text-left tablefont"
                        style="font-size: 15px; color: black"
                      >
                        <b>Name</b>
                      </th>
                      <th
                        class="text-left tablefont"
                        style="font-size: 15px; color: black"
                      >
                        <b>Action</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, i) in rolesList"
                      :key="i"
                      class="table"
                      
                    >
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td >
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td  >
                        <span v-if="value.roles">{{ value.roles }}</span>
                        <span v-else>-</span>
                      </td>
                      <td >
                        <v-icon
                          size="18"
                          class="pr-2"
                          color="#3F053C"
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          size="18"
                          color="#3F053C"
                          style="cursor: pointer"
                          @click="(deletedialog = true), (curId = value._id)"
                          >mdi-delete</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
       
          <v-layout wrap>
            <v-flex xs12 pt-4>
              <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
<v-card  v-else elevation="0">
  <v-layout>
            <v-flex xs12  pa-4 text-center class="tablefont1"> No data found </v-flex>
          </v-layout>
</v-card>
        <v-dialog width="400px" v-model="editdialogue">
          <v-card width="400px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Role</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="editdialogue = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
                <v-layout wrap>
                  <v-flex xs12 px-4 py-4>
                    <v-flex xs12 text-left py-1 class="tablefont">Name</v-flex>
                    <v-text-field
                      autofocus
                       color="#b234a9"
                      v-model="curItem.roles"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>

            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3F053C"
              class="buttons1"
                dark
                @click="editRole()"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="400px" v-model="deletedialog">
          <v-card width="400px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="deletedialog = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-card-text class="pa-4 tablefont"
              >Are you sure you want to delete this role?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="deletedialog = false"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3F053C"
              class="buttons1"
                dark
                @click="deleteRole()"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="400px" v-model="addRole">
          <v-card width="400px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Add Role</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="addRole = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 text-left px-4 pt-4 class="tablefont">Name</v-flex>

              <v-flex xs12 px-4 pt-2>
                <v-text-field
                  
                  placeholder="Roles"
                  color="#b234a9"
                  v-model="roles"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs2 px-4 pt-4>
                <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="AddRole()"
                >Add</v-btn
              >
              </v-flex> -->
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="addRole = false"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3F053C"
              class="buttons1"
                dark
                @click="AddRole()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page: 1,
      Pagelength: null,
      jewelleryId: null,
      curItem: [],
      rolesList: [],
      roles: null,
      curId: null,
      juwellaryId: localStorage.getItem("juwellaryId"),
      addRole:false,
      editdialogue: false,
      deletedialog: false,
    };
  },
  mounted() {
    this.getRoless();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
    getRoless() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/getalllist/roles",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          jewelleryId: this.juwellaryId,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.rolesList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    AddRole() {
      axios({
        url: "/add/roles",
        method: "POST",
        data: {
          roles: this.roles,
          jewelleryId: this.jewelleryId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.push('/MenuManagement')
            // this.addRole = false;
            // this.jewelleryId = null;
            // this.roles = null;
            // this.getRoless();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editRole() {
      axios({
        url: "/edit/roles",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          roles: this.curItem.roles,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getRoless();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getRoless();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteRole() {
      axios({
        url: "/remove/roles",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getRoless();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
